<form [formGroup]="formCompany" novalidate (ngSubmit)="save()">
  <div class="fields flex md:flex-row flex-col-reverse">
    <div>
      <div>
        <mat-form-field>
          <mat-label>{{ 'company-name' | transloco }}</mat-label>
          <input matInput formControlName="name" required>
          <app-form-error [formError]="formCompany.controls['name']?.errors"
                          [errorTextRequired]="'name_is_required' | transloco"
                          [showError]="formCompany.controls['name'].invalid && formCompany.controls['name'].touched">

          </app-form-error>
        </mat-form-field>
      </div>
      <div class="flex md:space-x-2 md:flex-row flex-nowrap flex-col">
        <mat-form-field>
          <mat-label>{{ 'country' | transloco }} *</mat-label>
          <mat-select #countrySelect formControlName="country">
            <mat-select-trigger>{{ countrySelect.value | transloco }}</mat-select-trigger>
            <mat-option>
              <ngx-mat-select-search
                [formControl]="countrySearch"
                [placeholderLabel]="'search' | transloco"
                [noEntriesFoundLabel]="'not-found' | transloco"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
              {{country | transloco}}
            </mat-option>
          </mat-select>
          <app-form-error [formError]="formCompany.controls['country']?.errors"
                          [errorTextRequired]="'name_is_required' | transloco"
                          [showError]="formCompany.controls['country'].invalid && formCompany.controls['country'].touched">

          </app-form-error>
          <mat-icon class="icon-tooltip" matTooltip="{{'country_description' | transloco}}" matSuffix>help_outline
          </mat-icon>
        </mat-form-field>

        <mat-form-field [hideRequiredMarker]="true">
          <mat-label>{{ 'company_type' | transloco }} *</mat-label>
          <mat-select formControlName="type" required>
            <mat-option class="capitalize" *ngFor="let type of companyTypes" [value]="type">
              {{type | transloco}}
            </mat-option>
          </mat-select>
          <!--                    <app-form-error [errorText]="'type is required'" [showError]="formCompany.contains('baseCounterparty').valueOf(''). && formCompany.controls['baseCounterparty[type]'].touched"></app-form-error>-->
        </mat-form-field>
      </div>
      <div *ngIf="companyType === enums.companyTypeEnum.PROVIDER_CLIENT && selectedCompany.type === enums.companyTypeEnum.PROVIDER"
        class="flex flex-col gap-5 sm:flex-row sm:gap-5">
        <mat-form-field>
          <mat-label>{{ 'currency-company' | transloco }}</mat-label>
          <mat-select formControlName="defaultCurrency" required>
            <mat-option *ngFor="let currency of currencies" [value]="currency">
              {{ currency | transloco}}
            </mat-option>
          </mat-select>
          <app-form-error [formError]="formCompany.controls['defaultCurrency'].errors"
                          [showError]="formCompany.invalid && formCompany.touched"></app-form-error>
        </mat-form-field>
        <!--        <mat-form-field formGroupName="baseCounterparty" fxFlex="50">-->
        <!--          <mat-label>{{ 'business-email' | transloco }}</mat-label>-->
        <!--          <input matInput formControlName="email" required>-->
        <!--          <app-form-error [formError]="formCompany.controls['baseCounterparty']['controls']['email'].errors"-->
        <!--                          [showError]="formCompany.invalid && formCompany.touched"></app-form-error>-->
        <!--        </mat-form-field>-->
      </div>

      <!--      <mat-form-field formGroupName="baseCounterparty">-->
      <!--        <mat-label>{{ 'legal-address' | transloco }}</mat-label>-->
      <!--        <input matInput formControlName="address" required>-->
      <!--        <app-form-error [formError]="formCompany.controls['baseCounterparty']['controls']['address'].errors" [showError]="formCompany.invalid && formCompany.touched"></app-form-error>-->
      <!--      </mat-form-field>-->

      <!--      <mat-checkbox color="primary" formControlName="isServiceProvider">-->
      <!--        {{ 'is-service-provider' | transloco }}-->
      <!--        &lt;!&ndash;        <a *ngIf="(user$ | async).settings?.lang === 'en'" class="ml-2 link-underline" href="https://content.ipnote.pro/docs/terms-for-partners-en.pdf" target="_blank">{{'terms_for_partners' | transloco}}</a>&ndash;&gt;-->
      <!--      </mat-checkbox>-->
    </div>
    <div class="md:ml-4 mb-2 md:mb-0 logotype hidden md:block">
      <app-upload-img class="rounded-full w-[135px] h-[135px]" formControlName="logo"><span class="text-white">Company<br>logo</span></app-upload-img>
    </div>
  </div>
  <button
    [disabled]="isLoading || formCompany.invalid || formCompany.disabled || formCompany.pristine || (companyCreateStore$ | async).waitingServerResponse"
    class="save-button"
    mat-raised-button
    color="primary">{{ 'add' | transloco }}</button>

</form>
