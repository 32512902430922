<mat-dialog-content>
  <h2 mat-dialog-title>{{ 'edit-phone' | transloco }}</h2>
  <form [formGroup]="form">
    <app-phone-field-control
      *ngIf="!isFilledPhoneNumber"
      [required]="true"
      formControlName="phone"
    ></app-phone-field-control>

    <ng-container *ngIf="isFilledPhoneNumber">
      <p class="text-center edit-phone-dialog__add-text">
        Please enter the code we’ve sent to <br> {{ phoneNumber }}
      </p>
      <div class="text-center mt-4">
        <mat-form-field class="w-[80px]" appearance="outline">
          <input
            class="text-center"
            maxlength="4"
            type="email"
            formControlName="verificationCode"
            matInput
            placeholder="- - - -"
          />
        </mat-form-field>
      </div>
      <div class="flex justify-around">
        <button
          mat-button
          color="primary"
          [disabled]="isTimerOn"
          (click)="send()"
        >Resend code</button>
        <button
          mat-button
          color="primary"
          [disabled]="isTimerOn"
          (click)="showNumberInput()"
        >Edit number</button>
      </div>
      <div *ngIf="isTimerOn" class=" text-center edit-phone-dialog__add-text">
        Resend code in {{ minutes | number : '2.0-0' }}:{{ seconds | number : '2.0-0' }}
      </div>
    </ng-container>
  </form>
  <mat-error *ngIf="isIncorrectPhoneNumber" class="text-center">{{ 'incorrect-phone' | transloco }}</mat-error>
</mat-dialog-content>
<mat-dialog-actions class="flex justify-center">
  <button
    *ngIf="!isFilledPhoneNumber"
    mat-raised-button
    type="button"
    color="primary"
    [disabled]="form.controls.phone.invalid || form.disabled || form.pristine"
    (click)="confirmPhoneNumber()"
  >
    {{ 'confirm-phone' | transloco }}</button>
  <button mat-button mat-dialog-close>{{ 'cancel' | transloco }}</button>
</mat-dialog-actions>
