import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Stripe, StripeElementsOptions, StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { environment } from '../../../../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
import {
  StripeCardComponent,
  StripeCardCvcComponent,
  StripeCardExpiryComponent,
  StripeCardGroupDirective,
  StripeCardNumberComponent,
  StripeElementsDirective,
  StripeElementsService,
  StripePaymentElementComponent,
  StripeService as StripeServiceLibrary,
} from 'ngx-stripe';
import { MatInputModule } from '@angular/material/input';
import { switchMap } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { StripeService } from '../../../page-modules/desk/services/stripe/stripe.service';
import { NgIf } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogService } from '../../services/dialogs/dialog.service';
import * as moment from 'moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export interface PayInvoiceDate {
  stripeInvoiceId: string;
  taskId: number;
}

@UntilDestroy()
@Component({
  selector: 'ipnote-stripe-payment-form',
  standalone: true,
  imports: [
    MatIconModule,
    ReactiveFormsModule,
    StripeCardNumberComponent,
    StripeCardExpiryComponent,
    StripeCardCvcComponent,
    StripeCardGroupDirective,
    StripeElementsDirective,
    StripePaymentElementComponent,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    StripeCardComponent,
    NgIf,
    TranslocoModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './stripe-payment-form.component.html',
  styleUrls: ['./stripe-payment-form.component.scss'],
  providers: [StripeElementsService, StripeServiceLibrary],
})
export class StripePaymentFormComponent implements OnInit {
  stripe: Stripe | null = null;
  stripeKey = environment?.stripe?.stripeKey;
  clientSecret: string;
  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    appearance: {
      theme: 'stripe',
      labels: 'floating',
      variables: {
        colorPrimary: '#673ab7',
      },
    },
  };
  isInvoicePaid = false;
  isInvoiceNotPaid = false;
  url = 'mailto:support@ipnote.pro';
  isDisabledPayButton = true;
  stripeCustomer: any;
  stripeInvoice: any;
  loadingStripeCustomer = true;
  loadingStripeInvoice = true;
  totalAmount = '';
  dueDate = '';
  isPaymentLoading = false;

  @ViewChild(StripePaymentElementComponent) paymentElement: StripePaymentElementComponent;

  constructor(
    public dialogRef: MatDialogRef<StripePaymentFormComponent>,
    private stripeServiceLibrary: StripeServiceLibrary,
    private stripeService: StripeService,
    @Inject(MAT_DIALOG_DATA) public data: PayInvoiceDate,
    private dialogs: DialogService,
  ) {}

  ngOnInit(): void {
    this.stripeService.getInvoiceClientSecret(this.data.stripeInvoiceId).subscribe((clientSecret) => {
      this.elementsOptions.clientSecret = clientSecret.secret;
    });

    this.getCustomer();
    this.getInvoiceForTask();
  }

  close(): void {
    this.dialogRef.close({ isInvoicePaid: this.isInvoicePaid });
  }

  pay(): void {
    this.isPaymentLoading = true;
    this.stripeService
      .getInvoiceClientSecret(this.data.stripeInvoiceId)
      .pipe(
        switchMap((clientSecret) =>
          this.stripeServiceLibrary.confirmPayment({
            elements: this.paymentElement.elements,
            redirect: 'if_required',
          }),
        ),
      )
      .subscribe((result) => {
        this.isPaymentLoading = false;
        if (result.error) {
          this.isInvoiceNotPaid = true;
          console.log(result.error.message);
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            this.isInvoicePaid = true;
          }
        }
      });
  }

  showInvoice() {
    const url = this.stripeInvoice.hosted_invoice_url;
    window.open(url, '_blank');
  }

  downloadInvoice(fileUrl: string, fileName: string): void {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  cardChange(card: StripePaymentElementChangeEvent) {
    this.isDisabledPayButton = !card.complete;
  }

  getCustomer() {
    this.stripeService
      .getCustomer()
      .pipe(untilDestroyed(this))
      .subscribe(
        (result) => {
          this.stripeCustomer = result;
          this.loadingStripeCustomer = false;
        },
        (error) => this.dialogs.error(error),
        () => (this.loadingStripeCustomer = false),
      );
  }

  getInvoiceForTask() {
    this.stripeService
      .getInvoiceForTask(this.data.taskId)
      .pipe(untilDestroyed(this))
      .subscribe(
        (result) => {
          this.stripeInvoice = result;
          this.totalAmount = this.splitLastTwoDigits(this.stripeInvoice.amount_due);
          this.dueDate = this.formatDueDate(this.stripeInvoice.due_date);
          console.log('loadingStripeInvoice', result);
          this.loadingStripeInvoice = true;
        },
        (error) => this.dialogs.error(error),
        () => (this.loadingStripeInvoice = false),
      );
  }

  splitLastTwoDigits(number) {
    const str = number.toString();
    if (str.length < 2) {
      return;
    }

    const lastTwoDigits = str.slice(-2);
    const remaining = str.slice(0, -2);
    return `$${remaining},${lastTwoDigits}`;
  }

  formatDueDate(timestamp: number): string {
    return `Due ${moment.unix(timestamp).format('D MMM YYYY')}`;
  }
}
