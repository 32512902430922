export const AI_PAGE_NOTIFICATIONS = {
  tasks: 'You have reached the task page',
  reminders: 'You have reached the reminders page',
  oips: 'You have reached the OIP page',
  searches: 'You have reached the search page',
  company: 'You have reached the company page',
  admin: 'You have reached the admin page',
  'chats-all': 'You have reached the all chats page',
  'manager-chats': 'You have reached the manager chats page',
};
