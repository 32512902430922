import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { AppConfiguration } from '../../../configuration/app.configuration';

@Injectable({
  providedIn: 'root',
})
export class BaseUrlService {
  constructor(private config: AppConfiguration) {}

  getBaseUrl(url: string): Observable<string> {
    const baseUrl = this.config.baseUrl;
    if (!baseUrl) {
      return throwError(new Error('CONFIG_BASE_URL is undefined'));
    }
    return of(`${baseUrl}/${url}`);
  }

  getFilesBaseUrl(): Observable<string> {
    const url = this.config.filesServerBaseUrl;
    if (!url) {
      return throwError(new Error('CONFIG_FILE_SERVER_BASE_URL is undefined'));
    }
    return of(`${url}`);
  }

  getAuthBaseUrl(url: string): Observable<string> {
    const base = this.config.authBaseUrl;
    if (!base) {
      return throwError(new Error('CONFIG_AUTH_BASE_URL is undefined'));
    }
    return of(`${base}/${url}`);
  }

  getChatsURL(url?: string): Observable<string> {
    const base = this.config.chatsBaseUrl;
    if (!base) {
      return throwError(new Error('CONFIG_CHATS_BASE_URL is undefined'));
    }
    return of(`${base}/${url}`);
  }
  getAppUrl(url?: string): Observable<string> {
    const base = this.config.appUrl;
    if (!base) {
      return throwError(new Error('CONFIG_WEBAPP_BASE_URL is undefined'));
    }
    return of(`${base}/${url}`);
  }
}
