import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { RouterSetCurrentUrl } from '../actions';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { concatMap, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import { closeNotificationsChanel, sendUrl } from '../actions/ai-widget-notifications.actions';
import { AiNotificationsRequestCommandEnum } from '@ipnote/enum';
import { of } from 'rxjs';

@Injectable()
export class RouterEffects {
  constructor(private actions$: Actions, private store: Store<AppState>) {}

  setCurrentUrl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RouterSetCurrentUrl),
      distinctUntilChanged(),
      withLatestFrom(this.store.select((state) => state.aiWindow.isChatWindowOpen)),
      concatMap(([res, isChatWindowOpen]) => {
        if (isChatWindowOpen) {
          return of({ type: 'NO_ACTION' });
        }

        this.store.dispatch(closeNotificationsChanel());
        this.store.dispatch(
          sendUrl({
            command: AiNotificationsRequestCommandEnum.URL_TRANSITION,
            url: res.urlCurrent,
          }),
        );

        return of({ type: 'NO_ACTION' });
      }),
    );
  });
}
