import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { VerificationCodePurpose, VerificationCodeType } from '@ipnote/enum';
import { DialogService } from '../../services/dialogs/dialog.service';
import { TwoFactorVerificationService } from '../../../page-modules/lobby/modules/set-up-two-factor/two-factor-verification.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, switchMap, tap } from 'rxjs/operators';
import { BaseUrlService } from '../../services/base-url/base-url.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StateUserProfile } from '../../../store/reducers/user';
import { selectStateUserProfile } from '../../../store/selectors/user.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { UserProfileUpdatePhoneError, UserProfileUpdatePhoneSuccess } from '../../../store/actions/user.actions';

@UntilDestroy()
@Component({
  selector: 'ipnote-edit-phone-dialog',
  templateUrl: './edit-phone-dialog.component.html',
  styleUrls: ['./edit-phone-dialog.component.scss'],
})
export class EditPhoneDialogComponent implements OnInit {
  seconds: number;
  minutes: number;
  interval;
  form = this.fb.group({
    phone: [null, Validators.required],
    verificationCode: [null, [Validators.required, Validators.max(4)]],
  });
  loading = false;
  codeType = VerificationCodeType.SMS;
  purpose = VerificationCodePurpose.PHONE_CONFIRMATION;
  VerificationCodeType = VerificationCodeType;
  isFilledPhoneNumber = false;
  isIncorrectPhoneNumber = false;
  userProfileStore$: Observable<StateUserProfile> = this.store.select(selectStateUserProfile);
  userId: number;

  constructor(
    public dialogRef: MatDialogRef<EditPhoneDialogComponent>,
    private fb: UntypedFormBuilder,
    private dialogs: DialogService,
    private twoFactorVerification: TwoFactorVerificationService,
    private cdr: ChangeDetectorRef,
    private baseUrl: BaseUrlService,
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.userProfileStore$
      .pipe(
        untilDestroyed(this),
        filter((up) => !!up.userProfile),
        tap((up) => {
          this.userId = up.userProfile.id;
        }),
      )
      .subscribe((result) => {});
  }

  get phoneNumber() {
    const phone = this.form.controls.phone.value;
    return `${phone.dialCode} ${phone.number}`;
  }

  get isTimerOn(): boolean {
    return this.minutes + this.seconds > 0;
  }

  ngOnInit() {
    this.form.controls.phone.valueChanges.pipe(untilDestroyed(this)).subscribe((val) => {
      this.isIncorrectPhoneNumber = false;
    });

    this.form.controls.verificationCode.valueChanges
      .pipe(
        filter((code) => code.length === 4),
        switchMap((code) => this.changeNumber(code)),
      )
      .subscribe({
        next: (updatedUserInfo) => {
          if (updatedUserInfo) {
            UserProfileUpdatePhoneSuccess();
            this.dialogRef.close({ phone: this.form.controls.phone.value });
          } else {
            this.form.controls.verificationCode.setErrors({ incorrect: true });
            this.form.controls.verificationCode.markAllAsTouched();
            this.isIncorrectPhoneNumber = true;
          }
        },
        error: (err) => UserProfileUpdatePhoneError(err),
      });
  }

  changeNumber(code: string) {
    const { dialCode, number, countryCode } = this.form.controls.phone.value;
    const newPhoneNumber = {
      code,
      phone: { dialCode, number, countryCode },
    };

    return this.baseUrl
      .getBaseUrl(`users/${this.userId}/phone`)
      .pipe(switchMap((url) => this.http.patch<{ success: boolean }>(url, newPhoneNumber)));
  }

  verification(code: string) {
    return this.twoFactorVerification.verifyCode({
      type: this.codeType,
      purpose: this.purpose,
      code: code,
    });
  }

  startTimer() {
    this.seconds = 29;
    this.minutes = 0;
    clearInterval(this.interval);
    this.interval = setInterval(this.updateTime.bind(this), 1000);
  }

  updateTime() {
    if (this.seconds > 0) {
      this.seconds--;
      if (this.seconds === 0) {
        if (this.minutes > 0) {
          this.minutes--;
          this.seconds = 60;
        }
      }
    }

    if (this.seconds === 0 && this.minutes === 0) {
      clearInterval(this.interval);
    }
  }

  send() {
    this.loading = true;
    const { dialCode, countryCode, e164Number } = this.form.controls.phone.value;
    this.twoFactorVerification
      .sendCode({
        phone: { number: e164Number.replace(dialCode, ''), dialCode: dialCode, countryCode: countryCode },
        type: this.codeType,
        purpose: this.purpose,
      })
      .pipe()
      .subscribe({
        next: (res) => {
          this.isFilledPhoneNumber = true;
          this.cdr.detectChanges();
          this.startTimer();
          this.loading = false;
        },
        error: (err) => {
          this.dialogs.error(err);
          this.loading = false;
        },
      });
  }

  showNumberInput() {
    this.isFilledPhoneNumber = false;
  }

  confirmPhoneNumber() {
    this.form.updateValueAndValidity();
    this.send();
  }
}
