import { UntilDestroy } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { StateUser } from '../reducers/user';
import { selectStateUser } from '../selectors/user.selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SocketIoService } from '../../app-common/services/socket.io/socket.io.service';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { concatMap, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { AiRequestNotificationsType, AiResponseNotificationsType } from '@ipnote/type';
import {
  initializeNotificationsChanel,
  openNotificationsChanel,
  sendUrl,
  setNotification,
} from '../actions/ai-widget-notifications.actions';
import { SOCKET_NAME_AI_WIDGET_NOTIFICATION } from '@ipnote/const';
import { AiNotificationsResponseCommandEnum, GatewayNamespaces } from '@ipnote/enum';
import { Socket } from 'socket.io-client/build/esm/socket';
import { selectStateAiWidgetWindow } from '../selectors/ai-widget.selectors';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AiWidgetNotificationsEffects {
  userState$: Observable<StateUser> = this.store.select(selectStateUser);
  private socketInitialized = false;
  private socketNotification: Socket | null;

  constructor(private actions$: Actions, private socketService: SocketIoService, private store: Store<AppState>) {}

  ConnectToNotificationsChanel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initializeNotificationsChanel),
        concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(selectStateUser)))),
        mergeMap(async ([res, user]) => {
          await this.initializeSocket();
          return new Promise<void>((resolve) => {
            const checkSocketInterval = setInterval(() => {
              if (this.socketNotification) {
                clearInterval(checkSocketInterval);
                resolve();
              }
            }, 100);
          });
        }),
        tap(() => {
          if (this.socketNotification) {
            this.socketNotification.on(SOCKET_NAME_AI_WIDGET_NOTIFICATION, async (res: AiResponseNotificationsType) => {
              if (!res) return;
              const { command, data } = res;
              switch (command) {
                case AiNotificationsResponseCommandEnum.NEW_NOTIFICATION:
                  this.store.dispatch(openNotificationsChanel());
                  this.store.dispatch(setNotification(data));
                  break;
              }
            });
          }
        }),
      ),
    {
      dispatch: false,
    },
  );

  SendUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sendUrl),
        withLatestFrom(this.store.select(selectStateAiWidgetWindow)),
        map(async ([action, state]) => {
          if (state.isChatWindowOpen) return;
          await this.send(action);
        }),
      ),
    { dispatch: false },
  );

  private async send(data: AiRequestNotificationsType) {
    await this.ensureSocketInitialized();
    this.socketNotification = this.socketService.getSocket(GatewayNamespaces.NOTIFICATIONS);
    if (this.socketNotification) {
      this.socketNotification.emit(SOCKET_NAME_AI_WIDGET_NOTIFICATION, data);
    } else {
      console.error('Socket is not initialized');
    }
  }

  private async initializeSocket() {
    if (!this.socketInitialized) {
      this.socketService.setupSocketConnection(GatewayNamespaces.NOTIFICATIONS);
      await this.ensureSocketInitialized();
      this.socketInitialized = true;
    }
  }

  private async ensureSocketInitialized() {
    return new Promise<void>((resolve) => {
      const checkSocket = () => {
        if (this.socketService.getSocket(GatewayNamespaces.NOTIFICATIONS)) {
          resolve();
        } else {
          setTimeout(checkSocket, 100);
        }
      };
      checkSocket();
    });
  }
}
