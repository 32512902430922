import { UserEffects } from './user.effects';
import { CompanyEffects } from './company.effects';
import { UpdateHistoryEffects } from './update-history.effects';
import { MarkifyDbsRecencyEffects } from './markify-dbs-recency.effects';
import { AiWidgetEffects } from './ai-widget.effects';
import { RouterEffects } from './router.effects';
import { AiWidgetNotificationsEffects } from './ai-widget-notifications.effects';

export const AllEffects = [
  UserEffects,
  CompanyEffects,
  UpdateHistoryEffects,
  MarkifyDbsRecencyEffects,
  AiWidgetEffects,
  RouterEffects,
  AiWidgetNotificationsEffects,
];
