import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyCreatorRoutingModule } from './company-creator-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { TranslocoModule } from '@ngneat/transloco';
import { AppCommonModule } from '../../../../../app-common/app-common.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '@visurel/iconify-angular';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMaskModule } from 'ngx-mask';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CompanyCreatorComponent } from './company-creator.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [CompanyCreatorComponent],
  imports: [
    CommonModule,
    CompanyCreatorRoutingModule,
    MatFormFieldModule,
    TranslocoModule,
    AppCommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    IconModule,
    MatTooltipModule,
    NgxMaskModule.forRoot(),
    MatSelectModule,
    FormsModule,
    NgxMatSelectSearchModule,
    MatMenuModule,
    MatAutocompleteModule,
  ],
  exports: [CompanyCreatorComponent],
})
export class CompanyCreatorModule {}
