<h2 mat-dialog-title *ngIf="data.title">{{data.title}}</h2>
<mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="set()">
    <div class="flex flex-col xs:flex-row gap-0 xs:gap-5">
      <mat-form-field class="flex-1">
        <mat-label>{{ 'request-url' | transloco }}</mat-label>
        <input matInput
               formControlName="request">
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex-1" style="text-align: right;">
    <button mat-button
            mat-dialog-close>
      {{ 'cancel' | transloco }}
    </button>
    <button mat-raised-button
            color="primary"
            [disabled]="form.invalid || form.pristine"
            (click)="set()">{{ 'set' | transloco }}</button>
    <button mat-raised-button
            (click)="goToLink()"
            [disabled]="form.invalid"
    >{{ 'open' | transloco }}</button>
  </div>
</mat-dialog-actions>

