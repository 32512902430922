import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import { MatButtonModule } from '@angular/material/button';
import { IconModule } from '@visurel/iconify-angular';
import { ContainerModule } from '../../directives/container/container.module';

@NgModule({
  imports: [CommonModule, MatButtonModule, IconModule, ContainerModule],
  declarations: [FooterComponent],
  exports: [FooterComponent],
})
export class FooterModule {}
