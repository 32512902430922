import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavItemComponent } from './sidenav-item.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { IconModule } from '@visurel/iconify-angular';
import { SafeStyleModule } from '../../pipes/safe-style/safe-style.module';
import { SharedModule } from '../../../@new_app/app/common/modules/shared-module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StopPreventDirective } from '../../../app/page-modules/desk/directives/stop-prevent.directive';

@NgModule({
  declarations: [SidenavItemComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatRippleModule,
    IconModule,
    SafeStyleModule,
    SharedModule,
    MatTooltipModule,
    StopPreventDirective,
  ],
  exports: [SidenavItemComponent],
})
export class SidenavItemModule {}
