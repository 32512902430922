import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxFilesizeModule } from 'ngx-filesize';
import { SharedModule } from '../../../../@new_app/app/common/modules/shared-module';
import { TranslocoModule } from '@ngneat/transloco';
import { IPublicFile } from '@ipnote/interface';
import { FilePreviewService } from '../../services/file-preview/file-preview.service';
import { DownloadService } from '../../services/download/download.service';
import { StopPreventDirective } from '../../../page-modules/desk/directives/stop-prevent.directive';

@Component({
  selector: 'ipnote-attachments-loader',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    NgxFilesizeModule,
    SharedModule,
    TranslocoModule,
    StopPreventDirective,
  ],
  templateUrl: './attachments-loader.component.html',
  styleUrls: ['./attachments-loader.component.scss'],
})
export class AttachmentsLoaderComponent {
  private filesLoading: IPublicFile[] = [];

  @Input() attachments: IPublicFile[];
  @Input() message: string;

  constructor(private filePreviewService: FilePreviewService, private downloadService: DownloadService) {}

  handleFile(file: IPublicFile): void {
    if (this.filePreviewService.openFile(file)) {
      return;
    } else {
      this.downloadFile(file);
    }
  }

  downloadFile(file: IPublicFile) {
    this.filesLoading.push(file);
    this.downloadService.getDownloadFileS3(file.url, file.name).subscribe({
      next: (value) => (this.filesLoading = this.filesLoading.filter((f) => f !== file)),
      error: (err) => (this.filesLoading = this.filesLoading.filter((f) => f !== file)),
    });
  }

  fileLoading(f: IPublicFile): boolean {
    return !!this.filesLoading.find((file) => file.url === f.url);
  }
}
