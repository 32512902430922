import { createReducer, on } from '@ngrx/store';
import {
  closeNotificationsChanel,
  initializeNotificationsChanel,
  openNotificationsChanel,
  removeNotificationToChat,
  sendNotificationToChat,
  setNotification,
} from '../../actions/ai-widget-notifications.actions';
import { AiNotificationMessageType } from '@ipnote/type';

export interface AiWidgetNotificationsState {
  initialized: boolean;
  isOpened: boolean;
  notification: AiNotificationMessageType;
  isMessageToSend: boolean;
}

export const initialState: AiWidgetNotificationsState = {
  initialized: false,
  isOpened: false,
  notification: null,
  isMessageToSend: false,
};

export const AiWidgetNotificationsReducer = createReducer(
  initialState,
  on(initializeNotificationsChanel, (state) => ({
    ...state,
    initialized: true,
  })),
  on(openNotificationsChanel, (state) => ({ ...state, isOpened: true })),
  on(closeNotificationsChanel, (state) => ({ ...state, isOpened: false })),
  on(sendNotificationToChat, (state) => ({ ...state, isMessageToSend: true })),
  on(removeNotificationToChat, (state) => ({ ...state, isMessageToSend: false })),
  on(setNotification, (state, notification) => ({
    ...state,
    notification: notification,
  })),
);
