<h2 mat-dialog-title *ngIf="data.title">{{data.title}}</h2>
<mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="set()">
    <mat-form-field>
      <mat-label>{{data.label}}</mat-label>
      <input matInput aria-label="New name" formControlName="name">
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex-1"></div>
  <button mat-button mat-dialog-close>{{ 'cancel' | transloco }}</button>
  <button mat-raised-button color="primary" (click)="set()">{{ 'set' | transloco }}</button>
</mat-dialog-actions>

