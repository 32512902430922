import { createAction, props } from '@ngrx/store';
import { AiNotificationMessageType, AiRequestNotificationsType } from '@ipnote/type';

export const initializeNotificationsChanel = createAction('[Notifications] Initialize Notifications Chanel');
export const openNotificationsChanel = createAction('[Notifications] Open Notifications Chanel');
export const closeNotificationsChanel = createAction('[Notifications] Close Notifications Chanel');
export const setNotification = createAction(
  '[Notifications] New Notification Received',
  (notification: AiNotificationMessageType) => notification,
);
export const sendUrl = createAction('[Notifications] Send Url', props<AiRequestNotificationsType>());
export const sendNotificationToChat = createAction('[Notifications] Send Notifications to Chat');
export const removeNotificationToChat = createAction('[Notifications] Remove Notifications to Chat');
