import { createAction, props } from '@ngrx/store';
import {
  IUserEmailChangeDto,
  IUserEmailConfirmationDto,
  IUserEmailConfirmationRes,
  IUserMeRes,
  IUserPasswordChangeDto,
  IUserPasswordRecoveryPostDto,
  IUserSignInAdminDto,
  IUserSignInDto,
  IUserSignInNewResDto,
  IUserSignInRes,
  IUserSignInResDto,
  IUserSignUpDto,
  IUserUpdateDto,
} from '@ipnote/interface';
import { ErrorResponseCreate } from '@ipnote/shared';
import { Params } from '@angular/router';

/*** User profile */
export const UserProfileLoad = createAction('[User] profile load');
export const UserProfileLoadSuccess = createAction('[User] profile load success', props<{ userProfile: IUserMeRes }>());
export const UserProfileLoadError = createAction('[User] profile load error');

export const UserProfileUpdateEmail = createAction('[User] update email', props<{ payload: IUserEmailChangeDto }>());
export const UserProfileUpdateEmailSuccess = createAction(
  '[User] update email success',
  props<{
    payload: IUserEmailChangeDto;
  }>(),
);
export const UserProfileUpdateEmailError = createAction(
  '[User] update email error',
  props<{
    error: ErrorResponseCreate;
  }>(),
);

export const UserProfileUpdatePassword = createAction(
  '[User] update password',
  props<{
    payload: IUserPasswordChangeDto;
  }>(),
);
export const UserProfileUpdatePasswordSuccess = createAction('[User] update password success');
export const UserProfileUpdatePasswordError = createAction(
  '[User] update password error',
  props<{
    error: ErrorResponseCreate;
  }>(),
);

export const UserProfileUpdatePhoneSuccess = createAction('[User] update phone success');
export const UserProfileUpdatePhoneError = createAction(
  '[User] update phone error',
  props<{
    error: ErrorResponseCreate;
  }>(),
);

export const UserProfileUpdate = createAction('[User] profile update', props<{ payload: IUserUpdateDto }>());
export const UserProfileUpdateSuccess = createAction('[User] profile update success');
export const UserProfileUpdateError = createAction('[User] profile update error');

/***
 * SignUp
 */
export const UserSignUp = createAction('[User] sign-up', props<{ user: IUserSignUpDto }>());
export const UserSignUpSuccess = createAction(
  '[User] sign-up success',
  props<{
    payload: IUserSignUpDto;
    userResponse: IUserSignInNewResDto;
  }>(),
);
export const UserSignUpReset = createAction('[User] sign-up reset');
export const UserSignUpError = createAction('[User] sign-up error', props<{ error: ErrorResponseCreate }>());

/****
 * SignIn
 */
export const UserSignIn = createAction('[User] sign-in', props<{ user: IUserSignInDto }>());
export const UserSignInGoogle = createAction('[User] sign-in google');
export const UserSignInGoogleCallback = createAction('[User] sign-in google callback', props<{ params: Params }>());
export const UserSignInLinkedIn = createAction('[User] sign-in linked-in');
export const UserSignInLinkedInCallback = createAction(
  '[User] sign-in linked-in callback',
  props<{ params: Params }>(),
);
export const UserSignInAdmin = createAction('[User] sign-in admin', props<{ user: IUserSignInAdminDto }>());
export const UserSignInSuccess = createAction(
  '[User] sign-in success',
  props<{ user: IUserSignInResDto; reboot: boolean }>(),
);
export const UserSignInError = createAction('[User] sign-in error', props<{ error: ErrorResponseCreate }>());

/***
 * SignOut
 */
export const UserSignOut = createAction('[User] sign-out');

/***
 * Confirm email
 */
export const UserConfirmEmail = createAction('[User] confirm email', props<{ data: IUserEmailConfirmationDto }>());
export const UserConfirmEmailSuccess = createAction(
  '[User] confirm email success',
  props<{
    result: IUserEmailConfirmationRes;
  }>(),
);
export const UserConfirmEmailError = createAction(
  '[User] confirm email error',
  props<{
    error: ErrorResponseCreate;
  }>(),
);

/***
 * Forgot password
 */
export const UserForgotPassword = createAction('[User] forgot password email', props<{ email: string }>());
export const UserForgotPasswordSuccess = createAction('[User] forgot password success');
export const UserForgotPasswordError = createAction(
  '[User] forgot password error',
  props<{
    error: ErrorResponseCreate;
  }>(),
);
export const UserForgotPasswordConfirm = createAction(
  '[User] forgot password confirm',
  props<{
    data: IUserPasswordRecoveryPostDto;
  }>(),
);
export const UserForgotPasswordConfirmSuccess = createAction(
  '[User] forgot password confirm success',
  props<{
    user: IUserSignInResDto;
  }>(),
);
export const UserForgotPasswordConfirmError = createAction(
  '[User] forgot password confirm error',
  props<{
    error: ErrorResponseCreate;
  }>(),
);

/***
 * User info
 */
export const UserSetData = createAction('[User] set new data', props<{ user: IUserSignInRes }>());
export const UserGetInfo = createAction('[User] get info');
export const UserSetNewRegistration = createAction('[User] User Set New Registration', (newRegistration: boolean) => ({
  newRegistration,
}));
export const UserSetKeyThrottler = createAction('[User] set key throttler', props<{ key: string }>());
export const UserGetKeyThrottler = createAction('[User] get key throttler');
export const UserSetAiActions = createAction('[User] set ai actions', props<{ aiActions: string }>());
export const UserSetCommand = createAction('[User] set command', props<{ command: string }>());
export const UserSetRedirectFromOldAiChatPage = createAction(
  '[User] set redirect from old ai chat page',
  props<{ redirect: boolean }>(),
);

/***
 * Back url
 */
export const UserCheckBackUrl = createAction('[User] check back url');
export const UserSetBackUrl = createAction('[User] save back url', props<{ url: string }>());
export const UserRedirectBackUrl = createAction('[User] redirect back url', props<{ url: string }>());
